import {BrowserModule} from '@angular/platform-browser'
import {NgModule} from '@angular/core'

import {AppRoutingModule} from './app-routing.module'
import {AppComponent} from './app.component'
import {BrowserAnimationsModule} from '@angular/platform-browser/animations'
import {CustomMaterialsModule} from './custom-materials-module'
import {FormsModule} from '@angular/forms'
import {HttpClientModule} from '@angular/common/http'
import {CommonModule} from '@angular/common'
import {MenuOverlayComponent} from './common/menu-overlay/menu-overlay.component'

@NgModule({
  declarations: [
    AppComponent,
    MenuOverlayComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    CustomMaterialsModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    CustomMaterialsModule,
  ],
  providers: [],
  entryComponents: [MenuOverlayComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
