import {Component} from '@angular/core'
import {PopoverRef} from '../../services/popover'
import {Router} from '@angular/router'

@Component({
  selector: 'pluto-menu-overlay',
  templateUrl: './menu-overlay.component.html',
  styleUrls: ['./menu-overlay.component.scss']
})
export class MenuOverlayComponent {

  constructor(
    private popoverRef: PopoverRef,
    private router: Router
  ) {
  }

  public navigate(location: string): void {
    this.popoverRef.close()
    this.router.navigate([location]).then()
  }
}
