import {TemplateRef, Type} from '@angular/core'
import {OverlayRef} from '@angular/cdk/overlay'

export type PopoverContent = TemplateRef<any> | Type<any> | string

export type PopoverParams<T> = {
  origin: HTMLElement //
  content: PopoverContent
  data?: T
  width?: string | number
  height?: string | number
}


export class PopoverRef<T = any> {

  constructor(public overlay: OverlayRef, public content: PopoverContent, public data: T) {
    // overlay.backdropClick().subscribe(() => this._close('backdropClick', data));
  }


  close() {
    this.overlay.dispose()
  }
}
