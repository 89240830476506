import {NgModule} from '@angular/core'
import {RouterModule, Routes} from '@angular/router'


const routes: Routes = [
  {
    path: '',
    redirectTo: 'register',
    pathMatch: 'full'
  },
  {
    path: 'fine',
    loadChildren: () => import('./fine/fine.module').then(m => m.FineModule),
  },
  {
    path: 'receive',
    loadChildren: () => import('./receive/receive.module').then(m => m.ReceiveModule),
  },
  {
    path: 'r',
    loadChildren: () => import('./receive/receive.module').then(m => m.ReceiveModule),
  },
  {
    path: 'register',
    loadChildren: () => import('./registry/registry.module').then(m => m.RegistryModule),
  }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {enableTracing: false, useHash: true, scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
